export const monthsRRVV = [
  // RRVVV
  { id: 0, name: 'Enero', day: 29, startDate: new Date(2022, 0, 1), value: new Date(2022, 0, 20) },
  {
    id: 1,
    name: 'Febrero',
    day: 29,
    startDate: new Date(2022, 1, 1),
    value: new Date(2022, 1, 18),
  },
  { id: 2, name: 'Marzo', day: 29, startDate: new Date(2022, 2, 1), value: new Date(2022, 2, 21) },
  { id: 3, name: 'Abril', day: 29, startDate: new Date(2022, 3, 1), value: new Date(2022, 3, 20) },
  { id: 4, name: 'Mayo', day: 29, startDate: new Date(2022, 4, 1), value: new Date(2022, 4, 20) },
  { id: 5, name: 'Junio', day: 29, startDate: new Date(2022, 5, 1), value: new Date(2022, 5, 20) },
  { id: 6, name: 'Julio', day: 29, startDate: new Date(2022, 6, 1), value: new Date(2022, 6, 22) },
  { id: 7, name: 'Agosto', day: 29, startDate: new Date(2022, 7, 1), value: new Date(2022, 7, 19) },
  {
    id: 8,
    name: 'Septiembre',
    day: 29,
    startDate: new Date(2022, 8, 1),
    value: new Date(2022, 8, 21),
  },
  {
    id: 9,
    name: 'Octubre',
    day: 29,
    startDate: new Date(2022, 9, 1),
    value: new Date(2022, 9, 20),
  },
  {
    id: 10,
    name: 'Noviembre',

    day: 29,
    startDate: new Date(2022, 10, 1),
    value: new Date(2022, 10, 21),
  },
  {
    id: 11,
    name: 'Diciembre',
    day: 29,
    startDate: new Date(2022, 11, 1),
    value: new Date(2022, 11, 20),
  },
];

export const monthsRRVV2023 = [
  // RRVVV
  { id: 0, name: 'Enero', day: 29, startDate: new Date(2023, 0, 1), value: new Date(2023, 0, 20) },
  {
    id: 1,
    name: 'Febrero',
    day: 29,
    startDate: new Date(2023, 1, 1),
    value: new Date(2023, 1, 20),
  },
  { id: 2, name: 'Marzo', day: 29, startDate: new Date(2023, 2, 1), value: new Date(2023, 2, 20) },
  { id: 3, name: 'Abril', day: 29, startDate: new Date(2023, 3, 1), value: new Date(2023, 3, 21) },
  { id: 4, name: 'Mayo', day: 29, startDate: new Date(2023, 4, 1), value: new Date(2023, 4, 19) },
  { id: 5, name: 'Junio', day: 29, startDate: new Date(2023, 5, 1), value: new Date(2023, 5, 20) },
  { id: 6, name: 'Julio', day: 29, startDate: new Date(2023, 6, 1), value: new Date(2023, 6, 21) },
  { id: 7, name: 'Agosto', day: 29, startDate: new Date(2023, 7, 1), value: new Date(2023, 7, 21) },
  {
    id: 8,
    name: 'Septiembre',
    day: 29,
    startDate: new Date(2023, 8, 1),
    value: new Date(2023, 8, 20),
  },
  {
    id: 9,
    name: 'Octubre',
    day: 29,
    startDate: new Date(2023, 9, 1),
    value: new Date(2023, 9, 20),
  },
  {
    id: 10,
    name: 'Noviembre',

    day: 29,
    startDate: new Date(2023, 10, 1),
    value: new Date(2023, 10, 20),
  },
  {
    id: 11,
    name: 'Diciembre',
    day: 29,
    startDate: new Date(2023, 11, 1),
    value: new Date(2023, 11, 20),
  },
];

export const monthsRRVV2024 = [
  // RRVVV
  { id: 0, name: 'Enero', day: 29, startDate: new Date(2024, 0, 1), value: new Date(2024, 0, 22) },
  {
    id: 1,
    name: 'Febrero',
    day: 29,
    startDate: new Date(2024, 1, 1),
    value: new Date(2024, 1, 21),
  },
  { id: 2, name: 'Marzo', day: 29, startDate: new Date(2024, 2, 1), value: new Date(2024, 2, 20) },
  { id: 3, name: 'Abril', day: 29, startDate: new Date(2024, 3, 1), value: new Date(2024, 3, 22) },
  { id: 4, name: 'Mayo', day: 29, startDate: new Date(2024, 4, 1), value: new Date(2024, 4, 21) },
  { id: 5, name: 'Junio', day: 29, startDate: new Date(2024, 5, 1), value: new Date(2024, 5, 21) },
  { id: 6, name: 'Julio', day: 29, startDate: new Date(2024, 6, 1), value: new Date(2024, 6, 22) },
  { id: 7, name: 'Agosto', day: 29, startDate: new Date(2024, 7, 1), value: new Date(2024, 7, 21) },
  {
    id: 8,
    name: 'Septiembre',
    day: 29,
    startDate: new Date(2024, 8, 1),
    value: new Date(2024, 8, 23),
  },
  {
    id: 9,
    name: 'Octubre',
    day: 29,
    startDate: new Date(2024, 9, 1),
    value: new Date(2024, 9, 22),
  },
  {
    id: 10,
    name: 'Noviembre',

    day: 29,
    startDate: new Date(2024, 10, 1),
    value: new Date(2024, 10, 22),
  },
  {
    id: 11,
    name: 'Diciembre',
    day: 29,
    startDate: new Date(2024, 11, 1),
    value: new Date(2024, 11, 23),
  },
];

export const monthsRRVV2025 = [
  // RRVVV
  { id: 0, name: 'Enero', day: 29, startDate: new Date(2025, 0, 1), value: new Date(2025, 0, 20) },
  {
    id: 1,
    name: 'Febrero',
    day: 29,
    startDate: new Date(2025, 1, 1),
    value: new Date(2025, 1, 20),
  },
  { id: 2, name: 'Marzo', day: 29, startDate: new Date(2025, 2, 1), value: new Date(2025, 2, 20) },
  { id: 3, name: 'Abril', day: 29, startDate: new Date(2025, 3, 1), value: new Date(2025, 3, 21) },
  { id: 4, name: 'Mayo', day: 29, startDate: new Date(2025, 4, 1), value: new Date(2025, 4, 20) },
  { id: 5, name: 'Junio', day: 29, startDate: new Date(2025, 5, 1), value: new Date(2025, 5, 20) },
  { id: 6, name: 'Julio', day: 29, startDate: new Date(2025, 6, 1), value: new Date(2025, 6, 18) },
  { id: 7, name: 'Agosto', day: 29, startDate: new Date(2025, 7, 1), value: new Date(2025, 7, 20) },
  {
    id: 8,
    name: 'Septiembre',
    day: 29,
    startDate: new Date(2025, 8, 1),
    value: new Date(2025, 8, 19),
  },
  {
    id: 9,
    name: 'Octubre',
    day: 29,
    startDate: new Date(2025, 9, 1),
    value: new Date(2025, 9, 20),
  },
  {
    id: 10,
    name: 'Noviembre',

    day: 29,
    startDate: new Date(2025, 10, 1),
    value: new Date(2025, 10, 20),
  },
  {
    id: 11,
    name: 'Diciembre',
    day: 29,
    startDate: new Date(2025, 11, 1),
    value: new Date(2025, 11, 19),
  },
];

export const monthsRenta = [
  // RT AT
  { id: 0, name: 'Enero', day: 25, startDate: new Date(2022, 0, 1), value: new Date(2022, 0, 25) },
  {
    id: 1,
    name: 'Febrero',
    day: 25,
    startDate: new Date(2022, 1, 1),
    value: new Date(2022, 1, 25),
  },
  { id: 2, name: 'Marzo', day: 25, startDate: new Date(2022, 2, 1), value: new Date(2022, 2, 25) },
  { id: 3, name: 'Abril', day: 23, startDate: new Date(2022, 3, 1), value: new Date(2022, 3, 25) },
  { id: 4, name: 'Mayo', day: 25, startDate: new Date(2022, 4, 1), value: new Date(2022, 4, 25) },
  { id: 5, name: 'Junio', day: 25, startDate: new Date(2022, 5, 1), value: new Date(2022, 5, 24) },
  { id: 6, name: 'Julio', day: 23, startDate: new Date(2022, 6, 1), value: new Date(2022, 6, 25) },
  { id: 7, name: 'Agosto', day: 25, startDate: new Date(2022, 7, 1), value: new Date(2022, 7, 25) },
  {
    id: 8,
    name: 'Septiembre',
    day: 24,
    startDate: new Date(2022, 8, 1),
    value: new Date(2022, 8, 23),
  },
  {
    id: 9,
    name: 'Octubre',
    day: 25,
    startDate: new Date(2022, 9, 1),
    value: new Date(2022, 9, 25),
  },
  {
    id: 10,
    name: 'Noviembre',
    day: 25,
    startDate: new Date(2022, 10, 1),
    value: new Date(2022, 10, 25),
  },
  {
    id: 11,
    name: 'Diciembre',
    day: 24,
    startDate: new Date(2022, 11, 1),
    value: new Date(2022, 11, 23),
  },
];

export const monthsRenta2023 = [
  // RT AT
  { id: 0, name: 'Enero', day: 25, startDate: new Date(2023, 0, 1), value: new Date(2023, 0, 25) },
  {
    id: 1,
    name: 'Febrero',
    day: 25,
    startDate: new Date(2023, 1, 1),
    value: new Date(2023, 1, 24),
  },
  { id: 2, name: 'Marzo', day: 25, startDate: new Date(2023, 2, 1), value: new Date(2023, 2, 24) },
  { id: 3, name: 'Abril', day: 23, startDate: new Date(2023, 3, 1), value: new Date(2023, 3, 25) },
  { id: 4, name: 'Mayo', day: 25, startDate: new Date(2023, 4, 1), value: new Date(2023, 4, 25) },
  { id: 5, name: 'Junio', day: 25, startDate: new Date(2023, 5, 1), value: new Date(2023, 5, 23) },
  { id: 6, name: 'Julio', day: 23, startDate: new Date(2023, 6, 1), value: new Date(2023, 6, 24) },
  { id: 7, name: 'Agosto', day: 25, startDate: new Date(2023, 7, 1), value: new Date(2023, 7, 25) },
  {
    id: 8,
    name: 'Septiembre',
    day: 24,
    startDate: new Date(2023, 8, 1),
    value: new Date(2023, 8, 25),
  },
  {
    id: 9,
    name: 'Octubre',
    day: 25,
    startDate: new Date(2023, 9, 1),
    value: new Date(2023, 9, 25),
  },
  {
    id: 10,
    name: 'Noviembre',
    day: 25,
    startDate: new Date(2023, 10, 1),
    value: new Date(2023, 10, 23),
  },
  {
    id: 11,
    name: 'Diciembre',
    day: 24,
    startDate: new Date(2023, 11, 1),
    value: new Date(2023, 11, 22),
  },
];

export const monthsRenta2024 = [
  // RT AT
  { id: 0, name: 'Enero', day: 25, startDate: new Date(2024, 0, 1), value: new Date(2024, 0, 25) },
  {
    id: 1,
    name: 'Febrero',
    day: 25,
    startDate: new Date(2024, 1, 1),
    value: new Date(2024, 1, 23),
  },
  { id: 2, name: 'Marzo', day: 25, startDate: new Date(2024, 2, 1), value: new Date(2024, 2, 25) },
  { id: 3, name: 'Abril', day: 23, startDate: new Date(2024, 3, 1), value: new Date(2024, 3, 25) },
  { id: 4, name: 'Mayo', day: 25, startDate: new Date(2024, 4, 1), value: new Date(2024, 4, 24) },
  { id: 5, name: 'Junio', day: 25, startDate: new Date(2024, 5, 1), value: new Date(2024, 5, 25) },
  { id: 6, name: 'Julio', day: 23, startDate: new Date(2024, 6, 1), value: new Date(2024, 6, 25) },
  { id: 7, name: 'Agosto', day: 25, startDate: new Date(2024, 7, 1), value: new Date(2024, 7, 23) },
  {
    id: 8,
    name: 'Septiembre',
    day: 24,
    startDate: new Date(2024, 8, 1),
    value: new Date(2024, 8, 25),
  },
  {
    id: 9,
    name: 'Octubre',
    day: 25,
    startDate: new Date(2024, 9, 1),
    value: new Date(2024, 9, 25),
  },
  {
    id: 10,
    name: 'Noviembre',
    day: 25,
    startDate: new Date(2024, 10, 1),
    value: new Date(2024, 10, 25),
  },
  {
    id: 11,
    name: 'Diciembre',
    day: 24,
    startDate: new Date(2024, 11, 1),
    value: new Date(2024, 11, 23),
  },
];

export const monthsRenta2025 = [
  // RT AT
  { id: 0, name: 'Enero', day: 25, startDate: new Date(2025, 0, 1), value: new Date(2025, 0, 25) },
  {
    id: 1,
    name: 'Febrero',
    day: 25,
    startDate: new Date(2025, 1, 1),
    value: new Date(2025, 1, 24),
  },
  { id: 2, name: 'Marzo', day: 25, startDate: new Date(2025, 2, 1), value: new Date(2025, 2, 25) },
  { id: 3, name: 'Abril', day: 23, startDate: new Date(2025, 3, 1), value: new Date(2025, 3, 25) },
  { id: 4, name: 'Mayo', day: 25, startDate: new Date(2025, 4, 1), value: new Date(2025, 4, 24) },
  { id: 5, name: 'Junio', day: 25, startDate: new Date(2025, 5, 1), value: new Date(2025, 5, 25) },
  { id: 6, name: 'Julio', day: 23, startDate: new Date(2025, 6, 1), value: new Date(2025, 6, 25) },
  { id: 7, name: 'Agosto', day: 25, startDate: new Date(2025, 7, 1), value: new Date(2025, 7, 23) },
  {
    id: 8,
    name: 'Septiembre',
    day: 24,
    startDate: new Date(2025, 8, 1),
    value: new Date(2025, 8, 25),
  },
  {
    id: 9,
    name: 'Octubre',
    day: 25,
    startDate: new Date(2025, 9, 1),
    value: new Date(2025, 9, 25),
  },
  {
    id: 10,
    name: 'Noviembre',
    day: 25,
    startDate: new Date(2025, 10, 1),
    value: new Date(2025, 10, 25),
  },
  {
    id: 11,
    name: 'Diciembre',
    day: 24,
    startDate: new Date(2025, 11, 1),
    value: new Date(2025, 11, 23),
  },
];
